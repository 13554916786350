import React from 'react';
import { CwLog, AsyncComponent } from 'cw-ui-core';

const componentLoader = path => async callback => callback(await import('./' + path));

const getScreen = (screenId, props = null) => {
    switch (screenId) {
        case 'home':
            return (
                <AsyncComponent
                    key={screenId}
                    {...props}
                    loader={componentLoader('components/Home/HomePageContainer')}
                />
            );
        case 'partners':
            return (
                <AsyncComponent
                    key={screenId}
                    {...props}
                    loader={componentLoader('components/Partner/PartnerListPageContainer')}
                />
            );
        case 'partner':
            return (
                <AsyncComponent
                    key={screenId}
                    {...props}
                    loader={componentLoader('components/Partner/PartnerPageContainer')}
                />
            );
        case 'products':
            return (
                <AsyncComponent
                    key={screenId}
                    {...props}
                    loader={componentLoader('components/Product/ProductListPageContainer')}
                />
            );
        case 'transactions':
            return (
                <AsyncComponent
                    key={screenId}
                    {...props}
                    loader={componentLoader('components/Transaction/TransactionListPageContainer')}
                />
            );
        case 'scheduledtransactions':
            return (
                <AsyncComponent
                    key={screenId}
                    {...props}
                    loader={componentLoader('components/ScheduledTransaction/ScheduledTransactionListPageContainer')}
                />
            );
        case 'productInstances':
            return (
                <AsyncComponent
                    key={screenId}
                    {...props}
                    loader={componentLoader('components/ProductInstance/ProductInstanceListPageContainer')}
                />
            );
        case 'productInstance':
            return (
                <AsyncComponent
                    key={screenId}
                    {...props}
                    loader={componentLoader('components/ProductInstance/EditProductInstanceContainer')}
                />
            );
        case 'creditCards':
            return (
                <AsyncComponent
                    key={screenId}
                    {...props}
                    loader={componentLoader('components/CreditCard/CreditCardListPageContainer')}
                />
            );
        case 'users':
            return (
                <AsyncComponent
                    key={screenId}
                    {...props}
                    loader={componentLoader('components/User/UserListPageContainer')}
                />
            );
        case 'user':
            return (
                <AsyncComponent
                    key={screenId}
                    {...props}
                    loader={componentLoader('components/User/EditUserContainer')}
                />
            );
        case 'product':
            return (
                <AsyncComponent
                    key={screenId}
                    {...props}
                    loader={componentLoader('components/Product/ProductPageContainer')}
                />
            );
        case 'company':
            return (
                <AsyncComponent
                    key={screenId}
                    {...props}
                    loader={componentLoader('components/Partner/CompanyPageContainer')}
                />
            );
        case 'provisionasio':
            return (
                <AsyncComponent
                    key={screenId}
                    {...props}
                    loader={componentLoader('components/ProvisionAsio/ProvisionAsioContainer')}
                />
            );
        case 'provisionasioessentials':
            return (
                <AsyncComponent
                    key={screenId}
                    {...props}
                    loader={componentLoader('components/ProvisionGoodBMS/ProvisionGoodBMSContainer')}
                />
            );
        case 'provisionProduct':
            return (
                <AsyncComponent
                    key={screenId}
                    {...props}
                    loader={componentLoader('components/ProvisionProduct/ProvisionProductPageContainer')}
                />
            );
        case 'entitlements':
            return (
                <AsyncComponent
                    key={screenId}
                    {...props}
                    loader={componentLoader('components/Entitlements/EntitlementsListPageContainer')}
                />
            );
        case 'client':
            return (
                <AsyncComponent
                    key={screenId}
                    {...props}
                    loader={componentLoader('components/Client/ClientDetailPageContainer')}
                />
            );
            case 'GOODBMSTRIALPAGE':
            return (
                <AsyncComponent
                    key={screenId}
                    {...props}
                    loader={componentLoader('components/ProvisionGoodBMS/GoodBMSTrialPage')}
                />
            );
            case 'provisionStatus':
            return (
                <AsyncComponent
                    key={screenId}
                    {...props}
                    loader={componentLoader('components/ProvisionStatus/ProvisionStatusContainer')}
                />
            );
            case 'packageDetail':
            return (
                <AsyncComponent
                    key={screenId}
                    {...props}
                    loader={componentLoader('components/Product/Package/PackageDetails/PackagePageContainer')}
                />
            );
            case 'auditlogs':
                return (
                    <AsyncComponent
                        key={screenId}
                        {...props}
                        loader={componentLoader('components/Partner/AuditLogs/AuditLogsContainer')}
                    />
                );
                case 'productauditlogs':
                return (
                    <AsyncComponent
                        key={screenId}
                        {...props}
                        loader={componentLoader('components/Product/ProductAuditLogs/ProductAuditLogsContainer')}
                    />
                );
        default:
            CwLog.error(`No screen found for ${screenId}`);
    }
};

export default getScreen;
