import * as core from 'cw-ui-core';
import getScreen from 'src/ScreenFactory';

const Routes = {
    HOME: {
        id: 'home',
        path: '/',
        exact: true,
        component: props => getScreen('home', props),
        icon: 'Object_HomeAutomation',
        showInMenu: true,
        name: () => 'Home',
    },
    PARTNERS: {
        id: 'partners',
        path: '/partners',
        exact: true,
        component: props => getScreen('partners', props),
        icon: 'Menu_Companies',
        showInMenu: true,
        name: () => 'Partners List',
    },
    PARTNER: {
        id: 'partner',
        path: '/partners/:partnerId',
        exact: true,
        regex: '/partners/' + core.REGEX_ANYTHING_MATCH,
        component: props => getScreen('partner', props),
    },
    PARTNER_TAB: {
        id: 'partner',
        path: '/partners/:partnerId/:tab',
        regex: '/partners/' + core.REGEX_ANYTHING_MATCH + '?/:tab',
        component: props => getScreen('partner', props),
    },
    TRANSACTIONS: {
        id: 'transactions',
        path: '/transactions',
        exact: true,
        component: props => getScreen('transactions', props),
        name: () => 'Transactions List',
    },
    SCHEDULED_TRANSACTIONS: {
        id: 'scheduled_transactions',
        path: '/scheduledtransactions',
        exact: true,
        component: props => getScreen('scheduledtransactions', props),
        name: () => 'Scheduled Transactions List',
    },
    CREDIT_CARDS: {
        id: 'credit_cards',
        path: '/creditcards',
        exact: true,
        component: props => getScreen('creditCards', props),
        name: () => 'Credit Card List',
    },
    USERS: {
        id: 'users',
        path: '/users',
        exact: true,
        component: props => getScreen('users', props),
        name: () => 'User List',
        icon: 'Object_Group',
        showInMenu: true,
    },
    USER: {
        id: 'user',
        path: '/users/:partnerId/user/:userId',
        exact: true,
        regex: '/users/' + core.REGEX_ANYTHING_MATCH + '/user/' + core.REGEX_ANYTHING_MATCH,
        component: props => getScreen('user', props),
    },
    PRODUCT: {
        id: 'product',
        path: '/products/:productId',
        exact: true,
        regex: '/products/' + core.REGEX_ANYTHING_MATCH,
        component: props => getScreen('product', props),
    },
    PRODUCT_TAB: {
        id: 'product',
        path: '/products/:productId/:tab',
        exact: true,
        regex: '/products/' + core.REGEX_ANYTHING_MATCH + '?/:tab',
        component: props => getScreen('product', props),
    },
    INSTANCES: {
        id: 'instances',
        path: '/instances',
        exact: true,
        component: props => getScreen('productInstances', props),
        name: () => 'Package & Product Instance List',
        icon: 'Workspace',
        showInMenu: true,
    },
    PRODUCT_INSTANCES: {
        id: 'product_instances',
        path: '/productinstances',
        exact: true,
        component: props => getScreen('productInstances', props),
        name: () => 'Product Instance List',
        showInMenu: false,
    },
    PRODUCT_INSTANCE: {
        id: 'product_instance',
        path: '/productinstance/:instanceId',
        regex: '/productinstance/' + core.REGEX_ANYTHING_MATCH,
        exact: true,
        component: props => getScreen('productInstance', props),
        showInMenu: false,
    },
    COMPANY: {
        id: 'company',
        path: '/companies/:companyId',
        component: props => getScreen('company', props),
        showInMenu: false,
    },
    CLIENTS: {
        id: 'clients',
        path: '/clients',
        exact: true,
        component: props => getScreen('clients', props),
        showInMenu: false,
    },
    CLIENT: {
        id: 'client',
        path: '/clients/:partnerId/client/:clientId',
        exact: true,
        component: props => getScreen('client', props),
        name: () => 'Client Detail',
        showInMenu: false,
    },
    PROVISION_ASIO: {
        id: 'provision_asio',
        path: '/partner/:partnerId/provisionasio',
        component: props => getScreen('provisionasio', props),
        name: () => 'Provision Asio',
        showInMenu: false,
    },
    PROVISION_GOOD_BMS: {
        id: 'provision_good_bms',
        path: '/partner/:partnerId/provisionasioessentials',
        component: props => getScreen('provisionasioessentials', props),
        name: () => 'Provision ConnnectWise Asio Essentials',
        showInMenu: false,
    },
    PROVISION_PRODUCT: {
        id: 'provision_product',
        path: '/partner/:partnerId/provisionProduct',
        component: props => getScreen('provisionProduct', props),
        name: () => 'Provision Product',
        showInMenu: false,
    },
    PRODUCTS: {
        id: 'products',
        path: '/products',
        exact: true,
        component: props => getScreen('products', props),
        icon: 'Application_CW_Marketplace',
        showInMenu: true,
        name: () => 'Product Catalog',
    },
    PROVISION_STATUS: {
        id: 'provision_status',
        path: '/partner/:partnerId/provision/:package/status',
        component: props => getScreen('provisionStatus', props),
        name: () => 'Provision Status',
        showInMenu: false,
    },
    GOOD_BMS_TRIAL_PAGE: {
        id: 'GOOD_BMS_TRIAL_PAGE',
        path: '/partner/:partnerId/GOODBMSTRIALPAGE',
        exact: true,
        component: props => getScreen('GOODBMSTRIALPAGE', props),
        //icon: 'Menu_Companies',
        showInMenu: false,
        name: () => 'Good BMS',
    },
    PACKAGES: {
        id: 'packages',
        path: '/products',
        exact: true,
        component: props => getScreen('products', props),
        showInMenu: false,
    },
    PACKAGE: {
        id: 'package',
        path: '/package/:packageId',
        exact: true,
        component: props => getScreen('packageDetail', props),
        showInMenu: false,
    },
    PACKAGE_TAB: {
        id: 'package',
        path: '/package/:packageId/:tab',
        regex: '/package/' + core.REGEX_ANYTHING_MATCH + '?/:tab',
        component: props => getScreen('packageDetail', props),
    },
    AUDITLOGS: {
        id: 'auditlogs',
        path: '/auditlogs',
        exact: true,
        component: props => getScreen('auditlogs', props),
        showInMenu: false,
        name: () => 'Audit Logs',
    },
    PRODUCTAUDITLOGS: {
        id: 'productauditlogs',
        path: '/productauditlogs',
        exact: true,
        component: props => getScreen('productauditlogs', props),
        showInMenu: false,
        name: () => 'Product Audit Logs',
    }
};

export default Routes;
